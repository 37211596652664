// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useHistory,
    useLocation,
} from 'react-router-dom'
import Select from 'react-select'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
} from 'data'

// pages
import {
    EditBlockSite,
} from 'pages'

// serializers
import {
    CustomCSSProperties,
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
    Template795SiteSerializer,
} from 'serializers/site'

// services
import {
    getStylesNew,
    parseQuerySite,
} from 'services'

// props
type TemplateBlock795SiteProps = {
    blockId: string
    content: Template795SiteSerializer | undefined
    isEditHovered: boolean
    isInComponent?: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    parentArray?: number[]
    parentStyles?: any
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const TemplateBlock795Site: React.FC<TemplateBlock795SiteProps> = React.memo(({
    blockId,
    content,
    isEditHovered,
    isInComponent,
    mainParentId,
    object,
    parentArray,
    parentStyles,
    styles,
    stylesEdit,
}) => {

    const dispatch = useDispatch()

    const history = useHistory()
    const location = useLocation()
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const deviceType = reduxModalSite.deviceType

    const [newValue, setValue] = useState<any>()
    const [stylesNew, setStylesNew] = useState<CustomCSSProperties | undefined>(applyStyles())

    useEffect(() => {
        setStylesNew(applyStyles())
    }, [
        object.id,
        reduxModalSite,
        styles,
        stylesEdit,
    ])

    useEffect(() => {
        if (content?.data) {
            setValue(getInitialValue(content.data))
        }
    }, [
        content?.data,
    ])

    function applyStyles() {
        try {
            return getStylesNew(reduxModalSite, styles, stylesEdit)
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock795Site',
                'applyStyles',
            ))
        }
    }

    function getInitialValue(listArray: any[]) {
        try {
            const searchObject = parseQuerySite(location.search)
            const filter_query = searchObject.filter_query
            const contentId = filter_query?.split('content_id')[1]?.split('-')[1]?.split(',')[0]
            const initialId = filter_query?.split(content?.filter_name!)[1]?.split(',')[0]?.split('-')[1]
            if (initialId && (Number(contentId) === content?.filter_related_content)) {
                return listArray.find((obj) => obj.id === Number(initialId))
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock795Site',
                'getInitialValue',
            ))
        }
    }

    function onInputChange(e: any) {
        try {
            setValue(e)
            let url_to_push_to = location.pathname
            const searchObject = parseQuerySite(location.search)
            let filter_query = searchObject.filter_query
            if (!filter_query) {
                filter_query = `content_id-${content?.filter_related_content}`
            }
            const contentId = filter_query?.split('content_id')[1]?.split('-')[1]?.split(',')[0]
            const keyValuePairs = filter_query?.split(',')
            const resultObject: any = {}
            keyValuePairs.forEach(pair => {
                const [key, value] = pair.split('-')
                resultObject[key] = parseInt(value, 10) // Convert value to an integer
            })
            if (e) {
                resultObject[content?.filter_name!] = e?.id
            } else {
                delete resultObject[content?.filter_name!]
            }
            if (Object.getOwnPropertyNames(resultObject).length > 1) {
                url_to_push_to += `?filter_query=content_id-${contentId}`
                for (const key in resultObject) {
                    if (resultObject.hasOwnProperty(key)) {
                        if (key !== 'content_id') url_to_push_to += `,${key}-${resultObject[key]}`
                    }
                }
            }
            history.push(url_to_push_to)
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock795Site',
                'onInputChange',
            ))
        }
    }

    let customStyles: any = {}
    if (stylesNew?.selectPrimaryColor) {
        customStyles = {
            clearIndicator: (provided: any, state: any) => ({
                ...provided,
                color: stylesNew.selectPrimaryColor, // your custom color for the "X"
            }),
            control: (provided: any, state: any) => ({
                ...provided,
                borderColor: stylesNew.selectPrimaryColor,  // 
                borderStyle: 'solid',  // 
                backgroundColor: stylesNew?.selectSecondaryColor, // your custom color for the separator
            }),
            dropdownIndicator: (provided: any, state: any) => ({
                ...provided,
                color: stylesNew.selectPrimaryColor, // your custom color for the arrow
            }),
            indicatorSeparator: (provided: any, state: any) => ({
                ...provided,
                backgroundColor: stylesNew.selectPrimaryColor, // your custom color for the separator
            }),
            input: (provided: any, state: any) => ({
                ...provided,
                // color: 'black',
                color: stylesNew.selectPrimaryColor,
            }),
            menu: (provided: any, state: any) => ({
                ...provided,
                zIndex: 9999,
            }),
            menuList: (provided: any, state: any) => ({
                ...provided,
                backgroundColor: stylesNew?.selectSecondaryColor, // your custom color for the separator
                zIndex: 9999,
            }),
            option: (provided: any, state: any) => ({
                ...provided,
                color: state.isSelected ? stylesNew?.selectSecondaryColor : stylesNew.selectPrimaryColor,
                ':hover': {
                    ...provided[':hover'],
                    backgroundColor: stylesNew.selectPrimaryColor,
                    color: stylesNew?.selectSecondaryColor,
                    cursor: 'pointer',
                },
            }),
            placeholder: (provided: any, state: any) => ({
                ...provided,
                color: stylesNew.selectPrimaryColor, // <-- Customize your placeholder color here
            }),
            singleValue: (provided: any, state: any) => ({
                ...provided,
                // color: 'black',
                color: stylesNew.selectPrimaryColor,
            }),
        }
    } else {

        const optionColorActive = 'white'
        const optionBackgroundActive = '#ed1550'

        customStyles = {
            input: (provided: any, state: any) => ({
                ...provided,
                color: 'black',
            }),
            menu: (provided: any, state: any) => ({
                ...provided,
                zIndex: 9999,
            }),
            menuList: (provided: any, state: any) => ({
                ...provided,
                zIndex: 9999,
            }),
            option: (provided: any, state: any) => ({
                ...provided,
                color: state.isSelected ? optionColorActive : 'black',
                ':hover': {
                    ...provided[':hover'],
                    backgroundColor: optionBackgroundActive,
                    color: optionColorActive,
                    cursor: 'pointer',
                },
            }),
            singleValue: (provided: any, state: any) => ({
                ...provided,
                color: 'black',
            }),
        }
    }

    return (
        <div
            id={blockId}
            className={`template-block-795${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
            style={stylesNew}
        >
            {/* {label && (
				<div className='hfis-form-label' >
					<label
						className='hfis-form-label-wrap'
						htmlFor={name}
					>
						<span style={stylesLabel}>
							{label}
						</span>
					</label>
				</div>
			)} */}
            <Select
                getOptionLabel={option => option.name}
                getOptionValue={option => `${option.id}`}
                isClearable
                isMulti={false}
                name={content?.filter_name}
                onChange={onInputChange}
                options={content?.data}
                placeholder={content?.text}
                styles={customStyles}
                value={newValue}
            />
            <EditBlockSite
                isInComponent={isInComponent}
                mainParentId={mainParentId || object.id!}
                object={object}
                parentArray={parentArray}
                parentStyles={parentStyles}
            />
        </div>
    )
})
