// react components
import React, {
    useState,
} from 'react'
import {
    IonContent,
    IonPage,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    LinkHelperSite,
} from 'components'

// data
import {
    defaultReduxState,
    media_url_site,
    reduxAuthAfterAuthUrlSetSite,
} from 'data'

// props
type NotFoundPortfolioSiteProps = {
    inModal?: boolean
    statusCode?: number
}

// main
export const NotFoundPortfolioSite: React.FC<NotFoundPortfolioSiteProps> = React.memo(({
    inModal,
    statusCode,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxCacheSite = useSelector((state: defaultReduxState) => state.reduxCacheSite)
    const reduxFormSitenavigationMode = useSelector((state: defaultReduxState) => state.reduxFormSite.navigationMode)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType

    const [backgroundImage] = useState<string>(`${media_url_site}global/not-found/dark/${Math.floor(Math.random() * 13)}.jpeg`)

    const statusText: any = {
        403: reduxText[8593],
        404: reduxText[8038],
    }
    const statusTextConnected: any = {
        403: reduxText[8717],
        404: reduxText[8038],
    }

    if (inModal) {
        return (
            <div className='not-found-page-site in-modal'>
                <div
                    className={`nfps-content ${deviceType}`}
                    style={{
                        backgroundImage: `url('${backgroundImage}')`
                    }}
                >
                    <div className='nfps-wrap'>
                        <p className='nfps-text1'>{statusCode || 404}</p>
                        <p className='nfps-text2'>{statusCode ? (reduxAuth?.authenticated ? statusTextConnected[statusCode] : statusText[statusCode]) : reduxText[8038]}</p>
                        {reduxCacheSite.portfolio?.id
                            ? (
                                <div>
                                    {(statusCode === 403 && !reduxAuth?.authenticated) && (
                                        <LinkHelperSite
                                            action='page_link'
                                            className='nfps-button bg'
                                            onClick={() => {
                                                if (!['edit', 'navMixed', 'preview'].includes(reduxFormSitenavigationMode!)) {
                                                    dispatch(reduxAuthAfterAuthUrlSetSite(`${window.location.pathname}${window.location.search}`))
                                                }
                                            }}
                                            to='login'
                                        >
                                            {reduxText[8536]}
                                        </LinkHelperSite>
                                    )}
                                    <LinkHelperSite
                                        action='page_link'
                                        className='nfps-button'
                                        onClick={() => undefined}
                                        to=''
                                    >
                                        {reduxText[8572]}
                                    </LinkHelperSite>
                                </div>
                            ) : (
                                <a
                                    className='nfps-button'
                                    href='https://www.whattheweb.org/'
                                >
                                    {reduxText[8572]}
                                </a>
                            )
                        }
                    </div>
                </div>
            </div>
        )
    }

    return (
        <IonPage className='not-found-page-site navbar-pt'>
            <IonContent className='mo-hide-ion-content-scrollbar'>
                <div
                    className='nfps-content'
                    style={{
                        backgroundImage: `url('${backgroundImage}')`
                    }}
                >
                    <div className='nfps-wrap'>
                        <p className='nfps-text1'>{statusCode || 404}</p>
                        <p className='nfps-text2'>{statusCode ? (reduxAuth?.authenticated ? statusTextConnected[statusCode] : statusText[statusCode]) : reduxText[8038]}</p>
                        {reduxCacheSite.portfolio?.id
                            ? (
                                <div>
                                    {(statusCode === 403 && !reduxAuth?.authenticated) && (
                                        <LinkHelperSite
                                            action='page_link'
                                            className='nfps-button bg'
                                            onClick={() => {
                                                if (!['edit', 'navMixed', 'preview'].includes(reduxFormSitenavigationMode!)) {
                                                    dispatch(reduxAuthAfterAuthUrlSetSite(`${window.location.pathname}${window.location.search}`))
                                                }
                                            }}
                                            to='login'
                                        >
                                            {reduxText[8536]}
                                        </LinkHelperSite>
                                    )}
                                    <LinkHelperSite
                                        action='page_link'
                                        className='nfps-button'
                                        onClick={() => undefined}
                                        to=''
                                    >
                                        {reduxText[8572]}
                                    </LinkHelperSite>
                                </div>
                            ) : (
                                <a
                                    className='nfps-button'
                                    href='https://www.whattheweb.org/'
                                >
                                    {reduxText[8572]}
                                </a>
                            )
                        }
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
})
